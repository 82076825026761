import React, { createContext, useContext, useState, useEffect } from 'react';

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const getDefaultLanguage = () => {
    const savedLanguage = localStorage.getItem('language');
    if (!savedLanguage) {
      localStorage.setItem('language', 'ar'); // Default to Arabic if no language is set
      return 'ar';
    }
    document.documentElement.lang = savedLanguage;
    return savedLanguage;
  };

  const [l, setLanguage] = useState(getDefaultLanguage());

  useEffect(() => {
    setLanguage(getDefaultLanguage());
  }, []);

  const switchLanguage = () => {
    const newLanguage = l === 'ar' ? 'fr' : 'ar';
    setLanguage(newLanguage);

    localStorage.setItem('language', newLanguage);
  };

  return (
    <LanguageContext.Provider value={{ l, switchLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  return useContext(LanguageContext);
};
