import { useEffect, useState } from 'react';
import './Header.css';
import { Link, useNavigate } from 'react-router-dom';
import { useCommonData } from '../../context/fetchCommonData';
import News from '../news/News';
import LoadingSpinner from '../loading/LoadingSpinner';
import TraductionToggler from '../traductionToggler/TraductionToggler';

function Header() {
  const { c, isLoading } = useCommonData();

  const [OpenMenu, setOpenMenu] = useState(true);
  const router = useNavigate();

  useEffect(() => {
    setOpenMenu(false);
  }, [router]);

  /*
  const [offset, setOffset] = useState(0);
  
  useEffect(() => {
    const tickerContainer = document.querySelector('.top .container');
    const tickerWidth = tickerContainer?.offsetWidth;
    
    const scroll = () => {
      setOffset((prevOffset) =>
        prevOffset < -tickerWidth ? tickerWidth : prevOffset - 1
  );
};

    const interval = setInterval(scroll, 20000); // Adjust scroll speed as needed
    
    return () => clearInterval(interval);
  }, []);
  */

  if (isLoading) return <LoadingSpinner />;
  return (
    <>
      <div className='top'>
        <div className='ticker'>
          <News />
        </div>
      </div>
      <header>
        <div className='container'>
          <button
            className={`mobile-menu ${OpenMenu ? 'active' : ''}`}
            onClick={() => setOpenMenu((v) => !v)}
          >
            <div className='bar' />
            <div className='bar' />
            <div className='bar' />
          </button>
          <div className='logo'>
            <Link to={'/'}>
              <img src='./imgs/Logo RDT.png' alt='logo' />
              <img src='./imgs/logo-mobile.png' alt='logo' className='mobile' />
            </Link>
          </div>

          <menu>
            <ul>
              <li>
                <Link to={'/'} style={{ fontWeight: 500 }}>
                  {c('Draa-Tafilalet region')}
                </Link>
                <div className='submenu'>
                  <ul>
                    <li>
                      <Link to={'/مونوغرافية_الجهة'}>
                        {c('Monography of the region')}
                      </Link>
                    </li>
                    <li>
                      <Link to={'/المؤهلات'}>{c('Qualifications')}</Link>
                      <>
                        <div className='submenu'>
                          <ul>
                            <li>
                              <Link to={'/المؤهلات#المؤهلات_الثقافية'}>
                                {c('Cultural')}
                              </Link>
                            </li>
                            <li>
                              <Link to={'/المؤهلات#المؤهلات_الاقتصادية'}>
                                {c('Economic')}
                              </Link>
                            </li>
                            <li>
                              <Link to={'/المؤهلات#المؤهلات_الطبيعية'}>
                                {c('Natural')}
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                {/* <Link>
                </Link> */}
                {c('Region Council')}
                <div className='submenu'>
                  <ul>
                    <li>
                      <Link to={'/اختصاصات_الجهة'}>
                        {c('The entity’s competencies')}
                      </Link>
                    </li>
                    <li>
                      {c('Council composition')}
                      <div className='submenu'>
                        <ul>
                          <li>
                            <Link to={'/الرئيس'}>{c('President')}</Link>
                          </li>
                          <li>
                            <Link to={'/المكتب_المسير'}>
                              {c('The running office')}
                            </Link>
                          </li>
                          <li>
                            <Link to={'/اللجان_الدائمة'}>
                              {c('Permanent committees')}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <Link to={'/أعضاء_المجلس'}>{c('Council members')}</Link>
                    </li>
                    <li>
                      {c('Notepad')}
                      <div className='submenu'>
                        <ul>
                          <li>
                            <Link to={'/دورات_المجلس'}>
                              {c('Council sessions')}
                            </Link>
                          </li>
                          <li>
                            <Link to={'/اجتماعات_اللجان_الدائمة'}>
                              {c('Standing committee meetings')}
                            </Link>
                          </li>
                          <li>
                            <Link to={'/المواعيد'}>{c('Appointments')}</Link>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <Link to={'/اتفاقيات_وشراكات'}>
                        {c('Agreements and partnerships')}
                      </Link>
                    </li>
                    <li>
                      <Link to={'/وثائق_قانونية'}>{c('Legal documents')}</Link>
                    </li>
                    <li>
                      <Link to={'/ملتقيات_وفعاليات'}>
                        {c('Forums and events')}
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                {c('Regional administration')}
                <div className='submenu'>
                  <ul>
                    <li>
                      <Link to={'/التنظيم'}>{c('Organization')}</Link>
                    </li>
                    <li>
                      {c('Studies')}
                      <div className='submenu'>
                        <ul>
                          <li>
                            <Link to={'/برنامج_التنمية_الجهوية'}>
                              {c('Regional development programme')}
                            </Link>
                          </li>
                          <li>
                            <Link to={'/مشروع_التصميم_الجهوي_لإعداد_التراب'}>
                              {c(
                                'Regional design project for soil preparation'
                              )}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <Link to={'/الوكالة_الجهوية_لتنفيذ_المشاريع'}>
                        {c('Regional agency for project implementation')}
                      </Link>
                    </li>
                    <li>
                      <Link to={'/شركة_التنمية_الجهوية'}>
                        {c('Regional Development Company')}
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                {c('Administrative Affairs')}
                <div className='submenu'>
                  <ul>
                    <li>
                      <Link to={'/طلبات_العروض'}>
                        {c('Requests for offers')}
                      </Link>
                    </li>
                    <li>
                      <Link to={'/مباريات_التوظيف'}>
                        {c('Recruitment matches')}
                      </Link>
                    </li>
                    <li>
                      <Link to={'/العرائض'}> {c('Petitions')}</Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                {c('Structured programmes')}
                <div className='submenu'>
                  <ul>
                    <li>
                      <Link to={'/التثمين_الترابي_والطبيعي_للمناطق_الجبلية'}>
                        {c(
                          'Territorial and natural valuation of mountainous areas'
                        )}
                      </Link>
                    </li>
                    <li>
                      <Link to={'/البرنامج_الوطني_للماء'}>
                        {c('National Water Program 2022-2027')}
                      </Link>
                    </li>
                    <li>
                      <Link to={'/السدود_الصغيرة_والبحيرات_التلالية'}>
                        {c('Small dams and hill lakes')}
                      </Link>
                    </li>
                    <li>
                      <Link to={'/محاربة_الحرائق_وتهيئة_وتأهيل_الواحات'}>
                        {c(
                          'Fighting fires and preparing and rehabilitating oases'
                        )}
                      </Link>
                    </li>
                    <li>
                      <Link to={'/مشاريع_مستعجلة_ومهيكلة_بقطاع_الماء'}>
                        {c(
                          'Urgent and structured projects in the water sector'
                        )}
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <Link to={'/#media'} style={{ fontWeight: '500' }}>
                  {c('Journalism and media space')}
                </Link>
              </li>
            </ul>

            <div
              className={`overlay ${OpenMenu ? 'active' : ''}`}
              onClick={() => setOpenMenu(false)}
            />
          </menu>

          {/* <button className='btn'>{c('Discover the destination')}</button> */}
          <TraductionToggler />
        </div>
      </header>
    </>
  );
}

export default Header;

// const menuLinks = [
//   {
//     [c('Draa-Tafilalet region')]: [
//       c('Monography of the region'),
//       {
//         [c('Qualifications')]: [
//           c('Qualifications') + ' ' + c('Cultural'),
//           c('Qualifications') + ' ' + c('Economic'),
//           c('Qualifications') + ' ' + c('Natural'),
//         ],
//       },
//     ],
//   },
//   {
//     [c('Region Council')]: [
//       c('The entity’s competencies'),
//       {
//         [c('Council composition')]: [
//           c('President'),
//           c('The running office'),
//           c('Permanent committees'),
//         ],
//       },
//       c('Council members'),
//       {
//         [c('Notepad')]: [
//           c('Council sessions'),
//           c('Standing committee meetings'),
//           c('Appointments'),
//         ],
//       },
//       c('Agreements and partnerships'),
//       c('Legal documents'),
//       c('Forums and events'),
//     ],
//   },
//   {
//     [c('Regional administration')]: [
//       c('Organization'),
//       {
//         [c('Studies')]: [
//           c('Regional development programme'),
//           c('Regional design project for soil preparation'),
//         ],
//       },
//       c('Regional agency for project implementation'),
//       c('Regional Development Company'),
//     ],
//   },
//   {
//     [c('Administrative Affairs')]: [
//       c('Requests for offers'),
//       c('Recruitment matches'),
//       c('Petitions'),
//     ],
//   },
//   {
//     [c('Structured programmes')]: [
//       c('Territorial and natural valuation of mountainous areas'),
//       c('National Water Program 2022-2027'),
//       c('Small dams and hill lakes'),
//       c('Fighting fires and preparing and rehabilitating oases'),
//       c('Urgent and structured projects in the water sector'),
//     ],
//   },
//   [c('Journalism and media space')],
// ];
