import './loadingSpinner.css';

const LoadingSpinner = ({ height }) => {
  return (
    <div className='spinner-container' style={{ height: height }}>
      <div className='loading-spinner'></div>
    </div>
  );
};

export default LoadingSpinner;
