import { createContext, useContext } from 'react';
import useFetch from '../hooks/useFetch';
import { useLanguage } from './language';

const CommonDataContext = createContext();

export const CommonDataProvider = ({ children }) => {
  const { data, isLoading, isError, error } = useFetch('common');

  return (
    <CommonDataContext.Provider value={{ data, isLoading, isError, error }}>
      {children}
    </CommonDataContext.Provider>
  );
};

export const useCommonData = () => {
  const { l } = useLanguage();
  const { data, isLoading, isError, error } = useContext(CommonDataContext);

  const filterCommonData = (slug) => {
    if (isLoading || !data) return 'loading translation';

    const item = data.find((entry) => entry?.acf?.slug === slug);
    const filteredData = item ? item?.acf?.[l] : 'No translation found';

    return filteredData;
  };

  return { data, isLoading, isError, error, c: filterCommonData };
};
