import React, { lazy, Suspense } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import AppLayout from './AppLayout';

// import Home from './pages/Home';
// import Monography from './pages/Monography';
// import SpecializationEntity from './pages/specializationEntity/SpecializationEntity';
// import President from './pages/president/President';
// import RunningOffice from './pages/runningOffice/RunningOffice';
// import Qualifications from './pages/qualifications/Qualifications';
// import CouncilMembers from './pages/councilMembers/CouncilMembers';
// import CouncilSessions from './pages/councilSessions/CouncilSessions';
// import Commission from './pages/commission/Commission';
// import Appointments from './pages/appointments/Appointments';
// import Agreements from './pages/agreements/Agreements';
// import LegalDocuments from './pages/legalDocuments/LegalDocuments';
// import ForumsEvents from './pages/forumsEvents/ForumsEvents';
// import Organization from './pages/organization/Organization';
// import RegionalDevelopmentProgramme from './pages/regionalDevelopmentProgramme/RegionalDevelopmentProgramme';
// import EconomicPillar from './pages/economicPillar/EconomicPillar';
// import SocialPillar from './pages/socialPillar/SocialPillar';
// import DomainPillar from './pages/domainpillar/DomainPillar';
// import PillarCultureYouth from './pages/pillarCultureYouth/PillarCultureYouth';
// import RegionalDesignSoil from './pages/regionalDesignSoil/RegionalDesignSoil';
// import RegionalAgency from './pages/regionalAgency/RegionalAgency';
// import RegionalDevCompany from './pages/regionalDevCompany/RegionalDevCompany';
// import Petitions from './pages/petitions/Petitions';
// import ValuationTerNaturel from './pages/valuationTerNaturel/ValuationTerNaturel';
// import RequestsOffers from './pages/requestOffers/RequestsOffers';
// import RecruitmentOffers from './pages/recruitmentOffers/RecruitmentOffers';
// import PermanentCommittees from './pages/permanentCommittees/PermanentCommittees';
// import NewsDetail from './pages/newsDetail/NewsDetail';
// import Updates from './pages/updates/Updates';
// import UpdatesDetail from './pages/updatesDetail/UpdatesDetail';
// import LoadingSpinner from './components/loading/LoadingSpinner';
// Lazy load pages
const Home = lazy(() => import('./pages/Home'));
const Qualifications = lazy(() =>
  import('./pages/qualifications/Qualifications')
);
const Monography = lazy(() => import('./pages/Monography'));
const SpecializationEntity = lazy(() =>
  import('./pages/specializationEntity/SpecializationEntity')
);
const President = lazy(() => import('./pages/president/President'));
const RunningOffice = lazy(() => import('./pages/runningOffice/RunningOffice'));
const PermanentCommittees = lazy(() =>
  import('./pages/permanentCommittees/PermanentCommittees')
);
const CouncilMembers = lazy(() =>
  import('./pages/councilMembers/CouncilMembers')
);
const CouncilSessions = lazy(() =>
  import('./pages/councilSessions/CouncilSessions')
);
const Commission = lazy(() => import('./pages/commission/Commission'));
const Appointments = lazy(() => import('./pages/appointments/Appointments'));
const Agreements = lazy(() => import('./pages/agreements/Agreements'));
const LegalDocuments = lazy(() =>
  import('./pages/legalDocuments/LegalDocuments')
);
const ForumsEvents = lazy(() => import('./pages/forumsEvents/ForumsEvents'));
const Organization = lazy(() => import('./pages/organization/Organization'));
const RegionalDevelopmentProgramme = lazy(() =>
  import('./pages/regionalDevelopmentProgramme/RegionalDevelopmentProgramme')
);
const EconomicPillar = lazy(() =>
  import('./pages/economicPillar/EconomicPillar')
);
const SocialPillar = lazy(() => import('./pages/socialPillar/SocialPillar'));
const DomainPillar = lazy(() => import('./pages/domainpillar/DomainPillar'));
const PillarCultureYouth = lazy(() =>
  import('./pages/pillarCultureYouth/PillarCultureYouth')
);
const RegionalDesignSoil = lazy(() =>
  import('./pages/regionalDesignSoil/RegionalDesignSoil')
);
const RegionalAgency = lazy(() =>
  import('./pages/regionalAgency/RegionalAgency')
);
const RegionalDevCompany = lazy(() =>
  import('./pages/regionalDevCompany/RegionalDevCompany')
);
const RequestsOffers = lazy(() =>
  import('./pages/requestOffers/RequestsOffers')
);
const RecruitmentOffers = lazy(() =>
  import('./pages/recruitmentOffers/RecruitmentOffers')
);
const Petitions = lazy(() => import('./pages/petitions/Petitions'));
const ValuationTerNaturel = lazy(() =>
  import('./pages/valuationTerNaturel/ValuationTerNaturel')
);
const NewsDetail = lazy(() => import('./pages/newsDetail/NewsDetail'));
const Updates = lazy(() => import('./pages/updates/Updates'));
const UpdatesDetail = lazy(() => import('./pages/updatesDetail/UpdatesDetail'));
const LoadingSpinner = lazy(() =>
  import('./components/loading/LoadingSpinner')
);

const ProgrammeNationalEau = lazy(() =>
  import('./pages/ProgrammeNationalEau/ProgrammeNationalEau')
);

const SmallBaragLacs = lazy(() =>
  import('./pages/smallbaraglacs/SmallBaragLacs')
);

const LutteContreIncendies = lazy(() =>
  import('./pages/lutteContreIncendies/LutteContreIncendies')
);
const UrgentStructuredProjects = lazy(() =>
  import('./pages/urgentStructuredProjects/UrgentStructuredProjects')
);

const PrivacyPolicy = lazy(() =>
  import('./pages/privayAndCondition/PrivacyPolicy')
);
const ConditionsUtilization = lazy(() =>
  import('./pages/privayAndCondition/ConditionsUtilization')
);

function AppRouter() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <AppLayout>
          <Home />
        </AppLayout>
      ),
    },
    {
      path: '/المؤهلات',
      element: (
        <AppLayout>
          <Qualifications />
        </AppLayout>
      ),
    },
    {
      path: '/مونوغرافية_الجهة',
      element: (
        <AppLayout>
          <Monography />
        </AppLayout>
      ),
    },
    {
      path: '/اختصاصات_الجهة',
      element: (
        <AppLayout>
          <SpecializationEntity />
        </AppLayout>
      ),
    },
    {
      path: '/الرئيس',
      element: (
        <AppLayout>
          <President />
        </AppLayout>
      ),
    },
    {
      path: '/المكتب_المسير',
      element: (
        <AppLayout>
          <RunningOffice />
        </AppLayout>
      ),
    },
    {
      path: '/اللجان_الدائمة',
      element: (
        <AppLayout>
          <PermanentCommittees />
        </AppLayout>
      ),
    },
    {
      path: '/أعضاء_المجلس',
      element: (
        <AppLayout>
          <CouncilMembers />
        </AppLayout>
      ),
    },
    {
      path: '/دورات_المجلس',
      element: (
        <AppLayout>
          <CouncilSessions />
        </AppLayout>
      ),
    },
    {
      path: '/اجتماعات_اللجان_الدائمة',
      element: (
        <AppLayout>
          <Commission />
        </AppLayout>
      ),
    },
    {
      path: '/المواعيد',
      element: (
        <AppLayout>
          <Appointments />
        </AppLayout>
      ),
    },
    {
      path: '/اتفاقيات_وشراكات',
      element: (
        <AppLayout>
          <Agreements />
        </AppLayout>
      ),
    },
    {
      path: '/وثائق_قانونية',
      element: (
        <AppLayout>
          <LegalDocuments />
        </AppLayout>
      ),
    },
    {
      path: '/ملتقيات_وفعاليات',
      element: (
        <AppLayout>
          <ForumsEvents />
        </AppLayout>
      ),
    },
    {
      path: '/التنظيم',
      element: (
        <AppLayout>
          <Organization />
        </AppLayout>
      ),
    },
    {
      path: '/برنامج_التنمية_الجهوية',
      element: (
        <AppLayout>
          <RegionalDevelopmentProgramme />
        </AppLayout>
      ),
    },
    {
      path: '/الركيزة_الاقتصادية',
      element: (
        <AppLayout>
          <EconomicPillar />
        </AppLayout>
      ),
    },
    {
      path: '/الركيزة_الإجتماعية',
      element: (
        <AppLayout>
          <SocialPillar />
        </AppLayout>
      ),
    },
    {
      path: '/الركيزة_المجالية',
      element: (
        <AppLayout>
          <DomainPillar />
        </AppLayout>
      ),
    },
    {
      path: '/ركيزة_الثقافة_والشباب',
      element: (
        <AppLayout>
          <PillarCultureYouth />
        </AppLayout>
      ),
    },
    {
      path: '/مشروع_التصميم_الجهوي_لإعداد_التراب',
      element: (
        <AppLayout>
          <RegionalDesignSoil />
        </AppLayout>
      ),
    },
    {
      path: '/الوكالة_الجهوية_لتنفيذ_المشاريع',
      element: (
        <AppLayout>
          <RegionalAgency />
        </AppLayout>
      ),
    },
    {
      path: '/شركة_التنمية_الجهوية',
      element: (
        <AppLayout>
          <RegionalDevCompany />
        </AppLayout>
      ),
    },
    {
      path: '/طلبات_العروض',
      element: (
        <AppLayout>
          <RequestsOffers />
        </AppLayout>
      ),
    },
    {
      path: '/مباريات_التوظيف',
      element: (
        <AppLayout>
          <RecruitmentOffers />
        </AppLayout>
      ),
    },
    {
      path: '/العرائض',
      element: (
        <AppLayout>
          <Petitions />
        </AppLayout>
      ),
    },
    {
      path: '/التثمين_الترابي_والطبيعي_للمناطق_الجبلية',
      element: (
        <AppLayout>
          <ValuationTerNaturel />
        </AppLayout>
      ),
    },
    {
      path: '/الأخبار/:id',
      element: (
        <AppLayout>
          <NewsDetail />
        </AppLayout>
      ),
    },
    {
      path: '/المستجدات',
      element: (
        <AppLayout>
          <Updates />
        </AppLayout>
      ),
    },
    {
      path: '/المستجدات/:id',
      element: (
        <AppLayout>
          <UpdatesDetail />
        </AppLayout>
      ),
    },
    {
      path: '/البرنامج_الوطني_للماء',
      element: (
        <AppLayout>
          <ProgrammeNationalEau />
        </AppLayout>
      ),
    },
    {
      path: '/السدود_الصغيرة_والبحيرات_التلالية',
      element: (
        <AppLayout>
          <SmallBaragLacs />
        </AppLayout>
      ),
    },
    {
      path: '/محاربة_الحرائق_وتهيئة_وتأهيل_الواحات',
      element: (
        <AppLayout>
          <LutteContreIncendies />
        </AppLayout>
      ),
    },
    {
      path: '/مشاريع_مستعجلة_ومهيكلة_بقطاع_الماء',
      element: (
        <AppLayout>
          <UrgentStructuredProjects />
        </AppLayout>
      ),
    },
    {
      path: '/سياسة_الخصوصية',
      element: (
        <AppLayout>
          <PrivacyPolicy />
        </AppLayout>
      ),
    },
    {
      path: '/شروط_الإستخدام',
      element: (
        <AppLayout>
          <ConditionsUtilization />
        </AppLayout>
      ),
    },
  ]);

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <RouterProvider router={router} />
    </Suspense>
  );
}

export default AppRouter;
