import React, { Suspense, useEffect, useState } from 'react';
import AppRouter from './AppRouter';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './App.css';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { CommonDataProvider } from './context/fetchCommonData';
import { useLanguage } from './context/language';
import LoadingSpinner from './components/loading/LoadingSpinner';

function App() {
  const queryClient = new QueryClient();
  const { l } = useLanguage();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 200);
    return () => clearTimeout(timer);
  }, [l]);

  const loaderStyle = {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: '#fff',
    display: isLoading ? 'flex' : 'none',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '1000',
  };

  return (
    <QueryClientProvider client={queryClient}>
      <CommonDataProvider>
        <div style={loaderStyle}>
          <LoadingSpinner />
        </div>
        <Suspense fallback={<LoadingSpinner />}>
          <AppRouter />
        </Suspense>
      </CommonDataProvider>
    </QueryClientProvider>
  );
}

export default App;
