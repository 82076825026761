import './traductionToggler.css';
import { useLanguage } from '../../context/language';

function TraductionToggler() {
  const { l, switchLanguage } = useLanguage();

  return (
    <>
      <div className='traduction'>
        <input
          type='checkbox'
          id='switch'
          value={l}
          onChange={switchLanguage}
          checked={l === 'fr'}
        />
        <label htmlFor='switch'>
          <span>Fr</span>
          <span>اع</span>
        </label>
      </div>
    </>
  );
}

export default TraductionToggler;
