import React from 'react';
import useFetch from '../hooks/useFetch';
import { useLanguage } from '../context/language';
import LoadingSpinner from './loading/LoadingSpinner';
import { Link } from 'react-router-dom';

function Footer() {
  const { l } = useLanguage();
  const { data, isLoading } = useFetch('footer');

  let pageData = {};

  if (data) {
    pageData = data[0]?.acf;
  }

  if (isLoading) return <LoadingSpinner />;
  return (
    <footer>
      <div className='container'>
        <div className='item'>
          <h4>{pageData?.adrs_section?.title?.[l]}</h4>
          <p>{pageData?.adrs_section?.address?.[l]}</p>
        </div>
        <div className='item'>
          <h4>{pageData?.terms_section?.title?.[l]}</h4>
          <p>
            <Link to={'/سياسة_الخصوصية'}>
              {pageData?.terms_section?.privacy_policy?.[l]}
            </Link>
          </p>
          <p>
            <Link to={'/شروط_الإستخدام'}>
              {pageData?.terms_section?.terms_of_use?.[l]}
            </Link>
          </p>
        </div>
        <div className='item'>
          <h4>{pageData?.contact_section?.title?.[l]}</h4>
          <p>{pageData?.contact_section?.phone}</p>
          <p>{pageData?.contact_section?.email}</p>
        </div>
        <div className='item '>
          <h4>{pageData?.social_network?.title?.[l]}</h4>
          <div className='social'>
            <a href={pageData?.social_network?.facebook}>
              <button>
                <svg
                  width={24}
                  height={24}
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M9.19795 21.5H13.198V13.4901H16.8021L17.198 9.50977H13.198V7.5C13.198 6.94772 13.6457 6.5 14.198 6.5H17.198V2.5H14.198C11.4365 2.5 9.19795 4.73858 9.19795 7.5V9.50977H7.19795L6.80206 13.4901H9.19795V21.5Z'
                    fill='currentColor'
                  />
                </svg>
              </button>
            </a>

            <a href={pageData?.social_network?.youtube}>
              <button>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='ionicon'
                  viewBox='0 0 512 512'
                >
                  <title>Logo Youtube</title>
                  <path
                    d='M508.64 148.79c0-45-33.1-81.2-74-81.2C379.24 65 322.74 64 265 64h-18c-57.6 0-114.2 1-169.6 3.6C36.6 67.6 3.5 104 3.5 149 1 184.59-.06 220.19 0 255.79q-.15 53.4 3.4 106.9c0 45 33.1 81.5 73.9 81.5 58.2 2.7 117.9 3.9 178.6 3.8q91.2.3 178.6-3.8c40.9 0 74-36.5 74-81.5 2.4-35.7 3.5-71.3 3.4-107q.34-53.4-3.26-106.9zM207 353.89v-196.5l145 98.2z'
                    fill='currentColor'
                  />
                </svg>
              </button>
            </a>
          </div>
        </div>
      </div>
      <div className='copyright'>
        <div className='container'>
          <p>{pageData?.rights?.[l]}</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
