import { Link } from 'react-router-dom';
import { useLanguage } from '../../context/language';
import useFetch from '../../hooks/useFetch';
import LoadingSpinner from '../loading/LoadingSpinner';

function News() {
  const { l } = useLanguage();
  const { data, isLoading } = useFetch('news');

  let pageData = {};

  if (data) {
    pageData = Object.values(data);
  }

  if (isLoading) return <LoadingSpinner height={'100%'} />;

  return (
    <>
      {pageData.map((item, k) => (
        <p key={k}>
          <Link to={`/الأخبار/${item?.id}`}>{item?.acf?.news?.[l]}</Link>
        </p>
      ))}
    </>
  );
}
export default News;
