import React from 'react';
import Header from './components/Header/Header';
import Footer from './components/Footer';
import { ScrollRestoration } from 'react-router-dom';
// import { Toaster } from 'react-hot-toast';

function AppLayout({ children }) {
  return (
    <>
      <ScrollRestoration />
      <Header />
      {children}
      <Footer />
    </>
  );
}

export default AppLayout;
