import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const baseUrl = axios.create({
  baseURL: 'https://bordt.creativegroupe.com/wp-json/acf/v3/',
  params: { per_page: 100000 },
  headers: { 'Content-Type': 'application/json' },
});

const fetchFromApi = async ({ queryKey }) => {
  const [endpoint, params] = queryKey;
  const response = await baseUrl.get(endpoint, { params });
  return response.data;
};

const useFetch = (endpoint, params = {}, options = {}) => {
  return useQuery({
    queryKey: [endpoint, params],
    queryFn: fetchFromApi,
    staleTime: 5 * 60 * 1000, // Cache 5 minutes
    ...options,
  });
};

export default useFetch;
